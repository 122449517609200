const invokeWhenElementIsInDOM = (elementSelector, selectorOfNodeToObserve, callback) => {
  if (document.querySelector(elementSelector)) {
    // we found the intended target so invoke the `callback` and exit
    callback();
    return;
  }

  const nodeToObserve = selectorOfNodeToObserve ?
    document.querySelector(selectorOfNodeToObserve) :
    document.documentElement;

  if (nodeToObserve) {
    /**
     * `nodeToObserve` is found within the DOM so observe upon its mutations for
     * the existence of the original target node
     */
    const Observer = new MutationObserver(() => {
      if (document.querySelector(elementSelector)) {
        callback();
        Observer.disconnect();
      }
    });
    Observer.observe(nodeToObserve, { childList: true, subtree: true });
  } else {
    /**
     * `nodeToObserve` is not found within the DOM. So lets observe for that first off
     * of `document.documentElement` and then when we detect that `nodeToObserve` is 
     * present, then cancel the `document.documentElement` observer and begin observing
     * for the originally intended target node (the node that matches `elementSelector`) 
     * from `nodeToObserve`. 
     */
    invokeWhenElementIsInDOM(selectorOfNodeToObserve, null, () => {
      invokeWhenElementIsInDOM(elementSelector, selectorOfNodeToObserve, callback);
    });
  }
}

module.exports = {
  invokeWhenElementIsInDOM
}